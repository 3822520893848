import React from "react";
import Img from "gatsby-image";

interface Props {
    title: string,
    image: any,
    imageAlt: string,
    subtitle?: string,
    icon?: JSX.Element,
    className?: string,
}

export const SoftwareHeaderInfo: React.FC<Props> = ({ title, image, subtitle, icon, imageAlt, className = "", children }) => {
    return (
        <div className={`software-header-info-container ${className}`}>
            <div className="software-title-container">
                {icon &&
                    icon
                }
                <h1 className="title">{title}</h1>
            </div>
            {subtitle && <h2 className="subtitle">{subtitle}</h2>}
            <div className="image-container">
                <Img className="edge" fluid={image} alt={imageAlt} />
            </div>
        </div>
    )
}