import React from "react";

interface Props {
    fill: string;
}

export const WebDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="40" height="27" viewBox="0 0 40 27" fill={fill} className="icon">
            <path d="M27,27l-5.7-5.7l7.9-7.8l-7.9-7.8L27,0l12.5,12.4c0.6,0.6,0.6,1.6,0,2.3L27,27z" />
            <path d="M0.5,12.4c-0.6,0.6-0.6,1.6,0,2.3L13,27l5.7-5.7l-7.9-7.8l7.9-7.8L13,0L0.5,12.4z" />
        </svg>
    )
}