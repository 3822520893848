import React from "react";

interface Props {
    fill: string;
}

export const ArrowIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="25" height="21" viewBox="0 0 25 21" fill={fill}>
            <path d="M1.4,9.1L10.6,0L12,1.4L3.8,9.5H25v2H3.8l8.2,8.1L10.6,21l-9.2-9.1L0,10.5L1.4,9.1z" />
        </svg>
    )
}