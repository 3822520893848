import React, { useState } from "react";
import { ArrowIcon } from "../icons/ArrowIcon";
import { Minimap, MinimapData } from "./Minimap";
import { SkipToButton } from "./SkipToButton";

interface Props {
    theme: MinimapTheme,
    minimaps: MinimapData[],
    className?: string,
}

export enum MinimapTheme {
    LIGHT = "light",
    DARK = "dark",
}

export type MapData = {
    label: string,
    title: string,
    description: JSX.Element,
}

export const MinimapScroller: React.FC<Props> = ({ theme, minimaps, className = "" }) => {
    const [index, setIndex] = useState<number>(0);
    const [minimapX, setMinimapX] = useState({ transform: `translate(0%)` });
    const xFactor = 60;

    const previous = () => {
        let i = index - 1;
        if (i < 0) {
            i = minimaps.length - 1;
        }
        setIndex(i);
        setMinimapX({ transform: `translate(calc(${-(i / minimaps.length * 100)}% - ${xFactor * i}px))` });
    }
    const next = () => {
        let i = index + 1;
        if (i >= minimaps.length) {
            i = 0;
        }
        setIndex(i);
        setMinimapX({ transform: `translate(calc(${-(i / minimaps.length * 100)}% - ${xFactor * i}px)` });
    }

    const renderMinimaps = () => {
        const minimapElements: JSX.Element[] = [];

        for (let i = 0; i < minimaps.length; ++i) {
            const minimap = minimaps[i];
            const element = <Minimap key={`minimap-${i}`} minimapData={minimap} theme={theme} />
            minimapElements.push(element);
        }

        return minimapElements;
    }

    const renderSkipToButtons = () => {
        const skipToElements: JSX.Element[] = [];

        for (let i = 0; i < minimaps.length; ++i) {
            const element = <SkipToButton ariaLabel={`Jump to Phase ${i + 1}`} active={index === i} onClick={() => {
                setIndex(i);
                setMinimapX({ transform: `translate(calc(${-(i / minimaps.length * 100)}% - ${xFactor * i}px)` });
            }} />
            skipToElements.push(element);
        }

        return skipToElements;
    }

    return (
        <div className={`minimap-scroller ${theme}-themed ${className}`}>
            <button aria-label="Previous Button" onClick={previous} className="previous-button"><ArrowIcon fill="#ffffff" /></button>
            <button aria-label="Next Button" onClick={next} className="next-button"><ArrowIcon fill="#ffffff" /></button>
            <div className="minimap-scrollable-container">
                <div className={`minimap-positioned-container ${minimaps.length}-cards`} style={minimapX}>
                    {renderMinimaps()}
                </div>
            </div>
            <div className="skip-to-buttons">
                {renderSkipToButtons()}
            </div>
        </div>
    )
}