import React from "react";
import { StraightCover } from "../layout/StraightCover";
import { ContainerColour, ContainerSize, Direction } from "./DiagonalContainer";

interface Props {
    colour: ContainerColour;
    size: ContainerSize;
    className?: string;
    direction?: Direction;
    withPad?: boolean;
}

export const StraightContainer: React.FC<Props> = ({ colour, size, className = "", direction = Direction.RIGHT, withPad = false, children }) => {
    return (
        <div className={`straight-container ${colour}-straight ${size}-straight ${direction}-straight ${withPad ? "with-pad" : ""} ${className}`}>
            <>
                <StraightCover fill={"#ffffff"} className="top-cover" />
                <StraightCover fill={"#ffffff"} className="bottom-cover" />
            </>
            <div className="content">
                {children}
            </div>
        </div>
    )
}