import React, { useState } from "react";
import { MinimapBackground } from "./MinimapBackground";
import { MinimapButton } from "./MinimapButton";
import { MinimapTitle } from "./MinimapTitle";
import { MinimapSeparator } from "../icons/MinimapSeparator";
import { MinimapTheme } from "./MinimapScroller";

interface Props {
    theme: MinimapTheme,
    minimapData: MinimapData,
}

export type MinimapData = {
    index: number,
    data: MapData[],
}

export type MapData = {
    label: string,
    title: string,
    description: JSX.Element,
}

export const Minimap: React.FC<Props> = ({ theme, minimapData }) => {
    const [mapState, setMapState] = useState<number>(0);

    return (
        <div className="minimap-container">
            <div className="minimap">
                <div className="minimap-button-container">
                    <MinimapBackground />
                    <MinimapTitle theme={theme} label={`Phase ${minimapData.index}`} />
                    <MinimapButton theme={theme} label={minimapData.data[0].label} onClick={() => setMapState(0)} active={mapState === 0} />
                    <MinimapButton theme={theme} label={minimapData.data[1].label} onClick={() => setMapState(1)} active={mapState === 1} />
                    <MinimapButton theme={theme} label={minimapData.data[2].label} onClick={() => setMapState(2)} active={mapState === 2} />
                </div>
            </div>
            <div className="minimap-content">
                <h2>{minimapData.data[mapState].title}</h2>
                {minimapData.data[mapState].description}
            </div>
            <MinimapSeparator />
        </div>
    )
}