import React from "react";

interface Props {
    fill: string;
}

export const SaaSDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="45" height="27" viewBox="0 0 45 27" fill={fill} className="icon">
            <path d="M37.5,12.3c-0.4,0-0.9,0.1-1.3,0.1C35.6,5.5,29.7,0,22.5,0C16.2,0,10.9,4.2,9.3,9.9c-0.2,0-0.3-0.1-0.5-0.1
            C3.9,9.8,0,13.7,0,18.4C0,23.2,3.9,27,8.8,27h28.7c4.1,0,7.5-3.3,7.5-7.4C45,15.6,41.6,12.3,37.5,12.3z"/>
        </svg>
    )
}