import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout";
import { Navigation } from "../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../components/layout/ParallaxHeader";
import { SoftwareHeaderInfo } from "../components/layout/SoftwareHeaderInfo";
import { SoftwareDevelopmentIcon } from "../components/layout/icons/SoftwareDevelopmentIcon";
import { Container } from "../components/layout/Container";
import { StraightContainer } from "../components/layout/StraightContainer";
import { LeftContainer } from "../components/layout/LeftContainer";
import { RightContainer } from "../components/layout/RightContainer";
import { ContainerColour, ContainerSize } from "../components/layout/DiagonalContainer";
import { MinimapScroller, MinimapTheme } from "../components/layout/minimap/MinimapScroller";
import { softwareDevPageData } from "../content/pageData/SoftwareDevelopmentData";
import { BlobThree } from "../components/layout/blobs/BlobThree";
import { BlobTwo } from "../components/layout/blobs/BlobTwo";
import { BlobOne } from "../components/layout/blobs/BlobOne";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        edgeMobile: {
            childImageSharp: any;
        }
        edge: {
            childImageSharp: any;
        }
        web: {
            childImageSharp: any;
        }
        native: {
            childImageSharp: any;
        }
        saas: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const edgeMobile = data.edgeMobile.childImageSharp.fluid;
    const edge = data.edge.childImageSharp.fluid;

    const edgeSources = [
        edgeMobile,
        {
            ...edge,
            media: `(min-width: 768px)`,
        }
    ]
    return (
        <Layout
            pageTitle={softwareDevPageData.metaPageTitle}
            keywords={softwareDevPageData.metaKeywords}
            description={softwareDevPageData.metaDescription}
            url={"software-development"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.LARGE} straightBottom={true} className="software-header">
                <SoftwareHeaderInfo
                    title={softwareDevPageData.title}
                    subtitle={softwareDevPageData.subtitle}
                    icon={<SoftwareDevelopmentIcon fill={"#ffffff"} />}
                    image={edgeSources}
                    imageAlt={"The Twin Spires Edge blog website"}
                    className="centered-header"
                />
            </ParallaxHeader>
            <Container className="software-service-title-container">
                <h2 className="title">Services</h2>
            </Container>
            <StraightContainer colour={ContainerColour.ORANGE} size={ContainerSize.SMALL}>
                <LeftContainer
                    isSoftware={true}
                    icon={softwareDevPageData.services[0].icon}
                    title={softwareDevPageData.services[0].title}
                    description={softwareDevPageData.services[0].description}
                    image={data.web.childImageSharp.fluid}
                    imageAlt={"The Twin Spires Edge website featuring basketball"}
                    buttonConfig={softwareDevPageData.services[0].buttonConfig}
                />
            </StraightContainer>
            <Container className="software-container">
                <div className="blob-container">
                    <BlobThree fill={"#080a2e08"} />
                    <BlobTwo fill={"#080a2e08"} />
                </div>
                <RightContainer
                    isSoftware={true}
                    icon={softwareDevPageData.services[1].icon}
                    title={softwareDevPageData.services[1].title}
                    description={softwareDevPageData.services[1].description}
                    image={data.native.childImageSharp.fluid}
                    imageAlt={"Free Play Native App"}
                    buttonConfig={softwareDevPageData.services[1].buttonConfig}
                />
            </Container>
            <StraightContainer colour={ContainerColour.BLUE} size={ContainerSize.SMALL}>
                <LeftContainer
                    isSoftware={true}
                    icon={softwareDevPageData.services[2].icon}
                    title={softwareDevPageData.services[2].title}
                    description={softwareDevPageData.services[2].description}
                    image={data.saas.childImageSharp.fluid}
                    imageAlt={"Morpheus CMS"}
                    buttonConfig={softwareDevPageData.services[2].buttonConfig}
                />
            </StraightContainer>
            <Container className="software-container scroller-blobs">
                <h2 className="title">Our<br></br>Process</h2>
                <div className="blob-container">
                    <BlobThree fill={"#080a2e08"} />
                    <BlobOne fill={"#080a2e08"} />
                </div>
                <MinimapScroller
                    minimaps={softwareDevPageData.minimapData}
                    theme={MinimapTheme.LIGHT}
                    className="software-minimap"
                />
            </Container>
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        edgeMobile: file(relativePath: { eq: "assets/images/software-development/edge_mobile.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        edge: file(relativePath: { eq: "assets/images/software-development/edge.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp

                }
            }
        }
        web: file(relativePath: { eq: "assets/images/software-development/web.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp

                }
            }
        }
        native: file(relativePath: { eq: "assets/images/software-development/native.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp

                }
            }
        }
        saas: file(relativePath: { eq: "assets/images/software-development/saas.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp

                }
            }
        }
    }
`