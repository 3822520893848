

import React from "react";

interface Props {
}

export const MinimapBackground: React.FC<Props> = () => {
    return (
        <svg width="396" height="269" viewBox="0 0 396 269">
            <g id="outline">
                <rect id="border" x="6" y="20" className="st0" width="384" height="1" />
                <g>
                    <path className="st0" d="M4.5,16C2,16,0,18,0,20.5C0,23,2,25,4.5,25C7,25,9,23,9,20.5C9,18,7,16,4.5,16z M4.5,22C3.7,22,3,21.3,3,20.5
			        C3,19.7,3.7,19,4.5,19S6,19.7,6,20.5C6,21.3,5.3,22,4.5,22z"/>
                </g>
                <g>
                    <path className="st0" d="M391.5,16c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C396,18,394,16,391.5,16z M391.5,22
			        c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C393,21.3,392.3,22,391.5,22z"/>
                </g>
                <g>
                    <path className="st0" d="M4.5,260C2,260,0,262,0,264.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C9,262,7,260,4.5,260z M4.5,266
			        c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5C6,265.3,5.3,266,4.5,266z"/>
                </g>
                <rect x="4" y="22" className="st0" width="1" height="241" />
                <g>
                    <path className="st0" d="M297.5,260c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C302,262,300,260,297.5,260z
			        M297.5,266c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C299,265.3,298.3,266,297.5,266z"/>
                </g>
                <rect id="border_1_" x="6" y="264" className="st0" width="290" height="1" />
                <g>
                    <path className="st0" d="M392,22h-1l-91.9,241h1L392,22z" />
                </g>
                <path className="st0" d="M74,87L74,87c-7.2,0-13-5.8-13-13V43.3h-2V74v0c0,8.2,6.7,15,15,15h28.7v-2H74z" />
                <path className="st0" d="M301,87h-28.7v2H301v0c7.2,0,13,5.8,13,13v7.9v22.9v7.9h0c0,7.2-5.8,13-13,13h-28.7v2H301h0
		        c8.2,0,15-6.7,15-15v-7.9v-22.9V102v0C316,93.7,309.3,87,301,87z"/>
                <path className="st0" d="M74,214.1h28.7v-2H74v0c-7.2,0-13-5.8-13-13v-7.9v-14.9v-7.9h0c0-7.2,5.8-13,13-13h28.7v-2H74h0
		        c-8.2,0-15,6.7-15,15v7.9v14.9v7.9v0C59,207.4,65.7,214.1,74,214.1z"/>
            </g>
        </svg>
    )
}