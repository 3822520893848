import { SoftwareDevelopmentIcon } from "../../components/layout/icons/SoftwareDevelopmentIcon";
import { WebDevelopmentIcon } from "../../components/layout/icons/WebDevelopmentIcon";
import { NativeDevelopmentIcon } from "../../components/layout/icons/NativeDevelopmentIcon";
import { SaaSDevelopmentIcon } from "../../components/layout/icons/SaaSDevelopmentIcon";
import { MinimapData } from "../../components/layout/minimap/Minimap";
import React from "react";
import { ButtonColour, ButtonConfig, ButtonSize } from "../../components/layout/Button";

export const softwareDevPageData: SoftwareDevPageData = {
    metaPageTitle: "Software Development Services & Process - iDream Interactive",
    metaKeywords: "software development services, custom software development services, offshore software development, lean software development, software development team",
    metaDescription: "We are prepared to build the custom software you require to solve your individual needs. No matter the problem, we can come up with a creative solution.",
    title: "Software Development",
    subtitle: "We are prepared to build the custom software you require to solve your individualized problem.",
    icon: <SoftwareDevelopmentIcon fill={"#ffffff"} />,
    services: [
        {
            icon: <WebDevelopmentIcon fill="#03041d" />,
            title: "Web Development",
            description: <p>We specialize in developing technology to make the fastest and most optimized websites. We believe in customizing the latest and greatest technology to fit your individual needs.</p>,
            buttonConfig: {
                colour: ButtonColour.BLACK,
                ctaLabel: "Learn More",
                ctaLink: "/software-development/web-development",
                size: ButtonSize.MEDIUM,
                id: "blackGradient",
                ariaLabel: "Learn more about Web Development"
            }
        },
        {
            icon: <NativeDevelopmentIcon fill="#080a2e" />,
            title: "Native Development",
            description: <p>As mobile phones become more popular, the need for feature rich mobile applications has increased.  By creating an app in its native iOS or Android development platform, you have access to the latest and greatest technology specific to that platform.</p>,
            buttonConfig: {
                colour: ButtonColour.BLUE,
                ctaLabel: "Learn More",
                ctaLink: "/software-development/native-development",
                size: ButtonSize.MEDIUM,
                id: "blueGradient",
                ariaLabel: "Learn more about Native Development"
            }
        },
        {
            icon: <SaaSDevelopmentIcon fill="#ffffff" />,
            title: "SaaS Development",
            description: <p>From eCommerce, customer relationship management (CRM), enterprise resource planning (ERP), project management and more.  We can help create a solution for your needs.</p>,
            buttonConfig: {
                colour: ButtonColour.WHITE,
                ctaLabel: "Learn More",
                ctaLink: "/software-development/saas-development",
                size: ButtonSize.MEDIUM,
                id: "whiteGradient",
                ariaLabel: "Learn more about SaaS Development"
            }
        },
    ],
    minimapData: [
        {
            index: 1,
            data: [
                {
                    label: "Research",
                    title: "Research",
                    description: (<>
                        <p>Once we have a solid understanding of the software with concept drawings, we dive deep into research.</p>
                        <p>Are there other applications out there with a similar idea? It is our job to determine if the idea already exists and to let our clients know.</p>
                    </>),
                },
                {
                    label: "Design Document",
                    title: "Design Document",
                    description: (<>
                        <p>A simple, easy to read document that explains your product before it is built. This document answers key questions such as how the software is used, how it will be monetized, the look and feel and general overview of the app.</p>
                        <p>A design document maps out the concepts that will be used as a guide to create prototypes. It will not delve into such things as what technology to use behind the scenes.</p>
                    </>),
                },
                {
                    label: "Project Setup",
                    title: "Project Setup",
                    description: (<>
                        <p>During this time we will decide on which technologies will be used.  We will then create the code base and repositories required so that our development team can start coding.  The development team will create the necessary scripts for the build/deployment process.</p>
                    </>),
                },
            ]
        },
        {
            index: 2,
            data: [
                {
                    label: "UI/UX Prototyping",
                    title: "UI/UX Prototyping",
                    description: (<>
                        <p>We plan out the flow of your app in Miro, an online whiteboard solution.  Once we feel it flows well, we take that information and bring it into Adobe XD for an interactive demo.  By sharing our progress through these applications, we can quickly receive feedback from our clients.</p>
                    </>),
                },
                {
                    label: "Application Prototyping",
                    title: "Application Prototyping",
                    description: (<>
                        <p>During this phase, we develop the initial prototype of your software.  This may take several rounds of revisions and quality assurance testing.  At the end of this phase, you will have a working prototype of your application.</p>
                    </>),
                },
                {
                    label: "Automated Testing",
                    title: "Automated Testing",
                    description: (<>
                        <p>Not all testing has to be done manually with our quality assurance team.  Instead, we are able to build automated test cases.</p>
                        <p>This is done to increase the speed of the test, without the need to pay for additional hours.</p>
                    </>),
                },
            ]
        },
        {
            index: 3,
            data: [
                {
                    label: "Release Candidate",
                    title: "Release Candidate",
                    description: (<>
                        <p>Once all of our teams are confident that the build is stable, we deem it our release candidate.</p>
                        <p>A release candidate is the final stable version of the software which is a stopping point for new feature development.</p>
                    </>),
                },
                {
                    label: "Marketing Strategy",
                    title: "Marketing Strategy",
                    description: (<>
                        <p>We believe that your marketing is a key component to the success of your application.  The sooner we can start the conversation about how to get your app in front of your target audience, the better.</p>
                        <p>An updated website, social media posts or maybe even Twitch streamers. We have many recommendations of how we can help.</p>
                    </>),
                },
                {
                    label: "Launch Day",
                    title: "Launch Day",
                    description: (<>
                        <p>The day your software is released to the public is a big deal.  It is the grand opening of your new application. Let us help you bring the balloons and cut the cord to a successful launch.</p>
                    </>),
                },
            ]
        },
        {
            index: 4,
            data: [
                {
                    label: "Customer Support",
                    title: "Customer Support",
                    description: (<>
                        <p>Enabling the right support channel for your game is so important.   We can help you build a support team and automated methods to capture details about what your users were doing when they experienced a problem.</p>
                    </>),
                },
                {
                    label: "Service Management",
                    title: "Service Management",
                    description: (<>
                        <p>Once your software is online and generating your revenue, it is important that it stays that way.  We handle monitoring your services to ensure it’s always available.</p>
                        <p>Software updates and security patches are also included with this phase.</p>
                    </>),
                },
                {
                    label: "New Feature Development",
                    title: "New Feature Development",
                    description: (<>
                        <p>Lastly, plan forward and listen to your community for additional feature suggestions.  Although this may seem like the last stage of the process, it is not.  We thrive on long lasting relationships with our clients.  When you are ready for the next big update, we are here to help.</p>
                    </>),
                },
            ]
        },
    ]
}

type SoftwareDevPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    subtitle: string,
    icon: JSX.Element,
    services: SoftwareServiceData[],
    minimapData: MinimapData[],
}

export type SoftwareServiceData = {
    icon: JSX.Element,
    title: string,
    description: JSX.Element,
    buttonConfig: ButtonConfig,
}