import React from "react";

interface Props {
    fill?: string;
}
export const MinimapSeparator: React.FC<Props> = ({ fill = "#03041D" }) => {
    return (
        <svg width="101" height="252" viewBox="0 0 101 252" fill={fill} className="minimap-separator">
            <path d="M96.5,0C94,0,92,2,92,4.5c0,1.8,1.1,3.3,2.6,4l-89,234.6c-0.3-0.1-0.7-0.1-1.1-0.1C2,243,0,245,0,247.5
            c0,2.5,2,4.5,4.5,4.5s4.5-2,4.5-4.5c0-1.8-1.1-3.3-2.6-4l89-234.6c0.3,0.1,0.7,0.1,1,0.1C99,9,101,7,101,4.5C101,2,99,0,96.5,0z
            M4.5,249c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C6,248.3,5.3,249,4.5,249z M96.5,6
            C95.7,6,95,5.3,95,4.5C95,3.7,95.7,3,96.5,3C97.3,3,98,3.7,98,4.5C98,5.3,97.3,6,96.5,6z"/>
        </svg>
    )
}