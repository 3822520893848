import React from "react";

interface Props {
    onClick: () => void;
    ariaLabel: string,
    active?: boolean;
}

export const SkipToButton: React.FC<Props> = ({ onClick, ariaLabel, active }) => {
    return (
        <button aria-label={ariaLabel} onClick={onClick} className={`skip-to-button ${active ? "active" : ""}`}>
            <div className="display" />
        </button>
    )
}