import React from "react";

interface Props {
    fill: string;
}

export const NativeDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="16" height="27" viewBox="0 0 16 27" fill={fill} className="icon">
            <path d="M13.1,0H2.9C1.3,0,0,1.3,0,3v21c0,1.6,1.3,3,2.9,3h10.2c1.6,0,2.9-1.3,2.9-3V3C16,1.3,14.7,0,13.1,0z M11.6,21
            H4.4c-0.8,0-1.5-0.7-1.5-1.5v-15C2.9,3.7,3.6,3,4.4,3h7.3c0.8,0,1.5,0.7,1.5,1.5v15C13.1,20.3,12.4,21,11.6,21z"/>
        </svg>
    )
}